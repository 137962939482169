<template>
  <div>
    <QueryForm
      v-model="queryForm"
      :show-allocate-export="queryForm.queryType === 'stockout' && showBtn"
      :query-btn-loading="tableLoading"
      :show-export="true"
      @handleExport="handleExport('scm_dsb_stock_provision_export')"
      @handleAllocateExport="handleExport('scm_dsb_stock_provision_export_can_detail')"
      @query="handleQuery"
      @reset="handleQueryFormReset"
    >
      <el-form-item label="销售预测版本" required>
        <Select
          v-model="queryForm.fcstVersionId"
          :select-options="dsbVertionOptions"
          :configuration="{
            key: 'version',
            label: 'versionCode',
            value: 'version',
          }"
          :clearable="false"
          @change="handleChange"
        />
      </el-form-item>
      <el-form-item label="Style">
        <Select
          v-model="queryForm.styleIdList"
          api-key="styleList"
          multiple
          clearable
          :configuration="{ key: 'id', label: 'styleName', value: 'id' }"
        />
      </el-form-item>
      <el-form-item label="Color">
        <Select
          v-model="queryForm.colorNameList"
          api-key="colorList"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="Size">
        <Select
          v-model="queryForm.sizeNameList"
          api-key="sizeList"
          clearable
          multiple
        />
      </el-form-item>

      <el-form-item label="备货渠道">
        <Select
          v-model="queryForm.stockChannelIdList"
          api-key="StockChannelList"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="季节">
        <Select
          v-model="queryForm.seasonIdList"
          :select-options="_getAllCommodityDict('SEASON')"
          :configuration="optionsConfig"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="定位">
        <Select
          v-model="queryForm.positionIdList"
          api-key="position"
          clearable
          multiple
        />
      </el-form-item>

      <el-form-item label="品牌">
        <Select
          v-model="queryForm.brandIdList"
          :select-options="brandOptions"
          :configuration="{ key: 'id', label: 'brandName', value: 'id' }"
          clearable
          multiple
        />
      </el-form-item>

      <el-form-item label="平台/站点/店铺" :class="$i18n.locale">
        <el-cascader
          v-model="queryForm.platformSiteShopList"
          clearable
          collapse-tags
          filterable
          separator="/"
          :options="alldatas"
          :props="{
            value: 'dropDownId',
            label: 'dropDownName',
            children: 'dropDownList',
            multiple: true,
          }"
          style="width: 100%"
        />
      </el-form-item>
      <el-form-item label="类别" :class="$i18n.locale">
        <el-cascader
          v-model="queryForm.categoryIdList"
          :options="categoryOptions"
          collapse-tags
          clearable
          separator=">"
          :filter-method="dataFilter"
          filterable
          :props="{
            value: 'id',
            label: 'categoryName',
            children: 'sonCategory',
            multiple: true,
          }"
        />
      </el-form-item>

      <el-form-item label="SKU" :class="$i18n.locale">
        <el-input
          v-model="queryForm.skuList"
          placeholder="多个以换行符分隔"
          type="textarea"
          autosize
          resize="none"
        />
      </el-form-item>
    </QueryForm>
    <el-row :gutter="10" class="mb-2" type="flex" justify="space-between">
      <el-col :span="8">
        <el-button
          v-if="queryForm.queryType==='stockout'"
          v-permission="'handleAlloacate'"
          :disabled="!showBtn"
          :loading="alloacateLoading"
          type="primary"
          @click="handleAlloacate('', 'batch')"
        >库存调配</el-button>
      </el-col>
      <el-col
        :span="8"
      >调配周期：<el-input-number
        v-model="queryForm.provisionCycle"
        :min="0"
        :max="30"
        :disabled="disabled"
        :controls="false"
        :precision="0"
        style="width: 200px"
        @blur="handleBlur($event)"
      />
      </el-col>
    </el-row>
    <el-tabs v-model="queryForm.queryType" @tab-click="handleTabClick">
      <el-tab-pane label="查看缺货" name="stockout" />
      <el-tab-pane label="查看冗余" name="redundant" />
    </el-tabs>
    <Table
      ref="multipleTable"
      :show-expand-check="true"
      :table-data="tableData"
      max-height="800px"
      :columns="columns"
      :page-obj="pager"
      :check="true"
      expand
      expand-data="detailList"
      :expand-columns="expandColumns"
      :loading="tableLoading"
      @select="handleSelect"
      @selectExpandData="handleExpandSelect"
      @handleTableExpandChange="handleTableExpandChange"
      @handleSelectionChange="clickChange"
      @selectAll="handleSelectAll"
    >
      <el-table-column
        slot="lackNumber"
        :label="queryForm.queryType === 'stockout' ? '缺货数量' : '冗余库存'"
        width="100"
      >
        <template slot-scope="scope">
          {{
            queryForm.queryType === "stockout"
              ? scope.row.stockoutNumber
              : scope.row.redundantNumber
          }}
        </template>
      </el-table-column>
      <!-- 下钻数据 -->
      <el-table-column
        slot="stockoutNumber"
        :label="queryForm.queryType === 'stockout' ? '缺货数量' : '冗余库存'"
        width="100"
      >
        <template slot-scope="scope">
          {{
            queryForm.queryType === "stockout"
              ? scope.row.stockoutNumber
              : scope.row.redundantNumber
          }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="queryForm.queryType === 'stockout'"
        slot="operate"
        label="操作"
        width="100"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.canProvisionFlag === '是'"
            :disabled="!showBtn"
            type="text"
            :loading="alloacateLoading"
            @click="handleAlloacate(scope.row, 'single')"
          >调配</el-button>
        </template>
      </el-table-column>
    </Table>

    <Paging
      :pager="pager"
      end
      :page-size="pageSize"
      @pagination="pagerUpdate"
    />
  </div>
</template>

<script>
import { handleMonitorParams } from '@/utils'
import {
  trackSiteSearch,
  handleBreadTitle,
  trackExportEvent,
  trackEvent,
  trackBtnClickEvent,
  trackTabClickEvent,
  trackPaginationEvent
} from '@/utils/monitor'
import QueryForm from '@/components/QueryForm'
import Select from '@/components/Selection'
import Paging from '@/components/Pagination'
import Table from '@/components/Table'
import { redundantStockProvisionPage, provisionView } from '@/api/inventory-allocation'
import { dsbVersionList, getplatformSiteShop, getBaseCategory } from '@/api/scm-api'
import { omit, pick } from 'lodash'
import { queryWarehouseList, queryBrandList } from '@/api/listSelection'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { expandColumns, columns } from '../columns'
import apiList from '@/api/export'
import { preText } from '@/utils/index.js'

export default {
  components: {
    QueryForm,
    Select,
    Paging,
    Table
  },
  mixins: [commodityInfoDict],
  data() {
    return {
      key: 'Inventory-PlatformShop',
      pageSize: [10, 20, 30, 40, 50],
      list: [],
      alldatas: [],
      dsbVertionOptions: [],
      type: '',
      dialogVisible: false,
      optionsWarehouseConfig: {
        key: 'id',
        label: 'warehouseName',
        value: 'id'
      },
      optionsConfig: { key: 'val', label: 'label', value: 'val' },
      tableLoading: false,
      tableData: [],
      multipleSelectionTable: [],
      multipleExpandSelectionTable: [],
      queryForm: {
        queryType: 'stockout',
        provisionCycle: 0,
        fcstVersionId: '',
        platformSiteShopList: [],
        skuList: ''
      },
      validDialog: false,
      pager: {
        current: 1,
        size: 20,
        total: 0
      },
      validForm: { stateReason: '' },
      sizeOptions: [],
      warehouseOption: [],
      platOptions: [],
      categoryOptions: [],
      brandOptions: [],
      expandColumns,
      columns,
      alloacateLoading: false
    }
  },
  computed: {
    showBtn() {
      const datas = this.dsbVertionOptions
      return (
        this.queryForm.queryType === 'stockout' &&
        this.queryForm.fcstVersionId + '' ===
          datas[datas.length - 1]?.version + ''
      )
    },
    disabled() {
      const datas = this.dsbVertionOptions
      return (
        this.queryForm.fcstVersionId + '' !==
        datas[datas.length - 1]?.version + ''
      )
    },
    queryParams() {
      const { platformSiteShopList: list, categoryIdList = [], skuList } = this.queryForm
      const lastCategoryIdList = []
      if (Array.isArray(categoryIdList)) {
        for (let i = 0; i < categoryIdList.length; i++) {
          lastCategoryIdList.push(parseInt(categoryIdList[i].slice(-1)))
        }
      }
      const platformSiteShopList = list?.map((item) => {
        const [platformId, siteId, shopId] = item
        return { platformId, siteId, shopId }
      })

      return Object.assign({}, this.queryForm,
        { platformSiteShopList, categoryIdList: lastCategoryIdList, skuList: preText(skuList, true) }
      )
    }
  },

  async mounted() {
    const defaultForm = this.$store.getters.redundantForm || {}
    this.queryForm = Object.assign({}, this.$options.data.call(this).queryForm, defaultForm)
    // 从缓存拿平台站点店铺
    this.queryForm.platformSiteShopList =
      JSON.parse(localStorage.getItem(this.key)) || []
    this._dsbVersionList()
    this._queryWarehouseList()
    this._queryBrandList()
    this._listPlatformSiteShop()
    this._CategoryList()
  },
  methods: {
    dataFilter(node, val) {
      if (!!~node.text.indexOf(val) || !!~node.text.toUpperCase().indexOf(val.toUpperCase())) {
        return true
      }
    },
    handleTabClick() {
      this.handleQuery()
      trackTabClickEvent(
        handleBreadTitle(this.$route) + `切换至${this.queryForm.queryType === 'stockout' ? '查看缺货' : '查看冗余'}`
      )
    },
    cycleExport() {
      const data = []
      this.tableData.map((list) => {
        if (list.checked && Array.isArray(list.detailList)) {
          list.detailList.map((v) =>
            data.push({ sku: v.sku, stockChannelId: v.stockChannelId })
          )
        } else {
          Array.isArray(list.detailList) &&
            list.detailList.map((v) => {
              if (v.checked) {
                data.push({ sku: v.sku, stockChannelId: v.stockChannelId })
              }
            })
        }
      })
      return data
    },
    handleBlur(e) {
      if (!e.target.value) {
        this.queryForm.provisionCycle = 0
      }
      trackEvent([
        '调配周期修改次数',
        handleBreadTitle(this.$route) + `修改值${this.queryForm.provisionCycle}`
      ])
    },
    async _CategoryList() {
      const { datas } = await getBaseCategory()
      this.categoryOptions = datas
    },
    handleChange(e) {
      this.disabled ? (this.queryForm.provisionCycle = 0) : ''
    },
    handleExpandSelect({ row, rows }) {
      this.tableData.map((item) => {
        if (
          item.detailList.some(
            (ee) =>
              ee.sku === row.sku &&
              ee.stockChannelId === row.stockChannelId &&
              ee.styleId === row.styleId
          )
        ) {
          item.detailList.map((v) => {
            Object.assign(v, {
              checked: rows.some(
                (j) =>
                  v.sku === j.sku &&
                  v.stockChannelId === j.stockChannelId &&
                  v.styleId === j.styleId
              )
            })
          })
        }
        const isChecked = item.detailList.every((v) => v.checked)
        Object.assign(item, { checked: isChecked })
        this.$refs.multipleTable.$refs.table.toggleRowSelection(
          item,
          isChecked
        )
      })
    },
    handleTableExpandChange(row) {
      Array.isArray(row.detailList) &&
        row.detailList.map((e) => {
          if (e.checked) {
            this.$nextTick(() => {
              this.$refs.multipleTable.$refs['tableChild' + row.defaultKey] &&
                this.$refs.multipleTable.$refs[
                  'tableChild' + row.defaultKey
                ].toggleRowSelection(e, true)
            })
          }
        })
    },
    // 勾选主列表
    handleSelect({ rows, row }) {
      // 默认选中 可调配数据
      const checked = rows.some((item) => item.defaultKey === row.defaultKey)
      this.tableData.map((item, index) => {
        if (item.defaultKey === row.defaultKey) {
          this.$set(item, 'checked', checked)
          item.detailList.map((e) => {
            this.$set(e, 'checked', checked)
            this.$nextTick(() => {
              this.$refs.multipleTable.$refs['tableChild' + row.defaultKey] &&
                this.$refs.multipleTable.$refs[
                  'tableChild' + row.defaultKey
                ].toggleRowSelection(e, checked)
            })
          })
        }
      })
    },
    // 勾选主列表
    clickChange(val) {
      this.multipleSelectionTable = val
    },
    // 全选
    handleSelectAll(rows) {
      this.tableData.map((item) => {
        const checked = rows.some((v) => v.defaultKey === item.defaultKey)
        this.$set(item, 'checked', checked)
        item.detailList.map((e) => {
          this.$set(e, 'checked', checked)
          this.$nextTick(() => {
            this.$refs.multipleTable.$refs['tableChild' + item.defaultKey] &&
              this.$refs.multipleTable.$refs[
                'tableChild' + item.defaultKey
              ].toggleRowSelection(e, checked)
          })
        })
      })
    },

    async _listPlatformSiteShop() {
      const { datas } = await getplatformSiteShop()
      this.alldatas = datas
    },
    async _queryBrandList() {
      const { datas } = await queryBrandList()
      this.brandOptions = datas
    },
    async _dsbVersionList() {
      const { datas } = await dsbVersionList()
      this.dsbVertionOptions = datas
      this.queryForm.fcstVersionId = datas[datas.length - 1]?.version
      this.handleQuery()
    },
    async handleAlloacate(row, type) {
      try {
        let arr = []

        if (type === 'single') {
          // 如果是单个操作
          arr = row.sku ? [row] : row.detailList.filter(v => v.canProvisionFlag === '是')
        } else {
          // 如果是批量操作
          this.tableData.forEach(v => {
            v.detailList.forEach(vv => {
              if (vv.checked) {
                arr.push({ ...omit(v, 'detailList'), ...vv })
              }
            })
          })

          // 检查是否有可调配的数据
          if (!arr.length || arr.every(v => v.canProvisionFlag !== '是')) {
            return this.$message.warning('请勾选可调配数据')
          }

          // 检查是否选择了相同的styleId和stockChannelId
          const sameStyle = arr.some(v => v.styleId !== arr[0].styleId || v.stockChannelId !== arr[0].stockChannelId)
          if (sameStyle) {
            return this.$message.warning('仅选择同一style+备货渠道下可调配数据')
          }
        }

        const { provisionCycle } = this.queryForm

        // 过滤可调配数据
        arr = arr.filter(v => v.canProvisionFlag === '是')
        const provisionViewParams = {
          indicatorList: arr,
          provisionCycle
        }

        // 校验
        const skuStockChannelList = arr.map(v => pick(v, ['platformId', 'shopId', 'siteId', 'sku', 'stockChannelId']))
        this.alloacateLoading = true
        await provisionView({ provisionCycle, skuStockChannelList })
        this.alloacateLoading = false
        sessionStorage.setItem('provisionViewParams', JSON.stringify(provisionViewParams))
        this.$router.push({
          path: 'allocation',
          append: true
        })

        trackBtnClickEvent(handleBreadTitle(this.$route) + `${type === 'single' ? '列表单个调配' : '批量调配'}`)
      } finally {
        this.alloacateLoading = false
      }
    },

    async _queryWarehouseList() {
      const { datas } = await queryWarehouseList()
      this.warehouseOption = datas
    },

    async handleExport(key) {
      const skuStockChannelList = this.cycleExport()
      if (!skuStockChannelList.length) return this.$message.warning('请先勾选需导出数据')
      const params = Object.assign({}, this.queryParams, { skuStockChannelList })
      await apiList[key](params)
      this.$notify({
        message: '操作成功，请前往文件导出界面下载',
        type: 'success'
      })
      trackExportEvent(handleBreadTitle(this.$route) + `${key === 'scm_dsb_stock_provision_export' ? '列表导出' : '明细导出'}`)
    },

    pagerUpdate(val) {
      this.pager = val
      this._queryLists()
      trackPaginationEvent(
        handleBreadTitle(this.$route) + `条目数${val.size}-当前页${val.current}`
      )
    },
    handleQuery() {
      this.pager.current = 1
      this._queryLists()
      trackSiteSearch(
        JSON.stringify(handleMonitorParams(this.queryParams)),
        handleBreadTitle(this.$route),
        this.pager.size
      )
    },

    async _queryLists() {
      try {
        this.tableLoading = true
        const {
          code,
          datas: {
            pager: { total },
            records = []
          }
        } = await redundantStockProvisionPage(this.pager, this.queryParams)
        if (code === 0) {
          this.tableData = records.map((v) => {
            return { ...v, defaultKey: `${v.styleId}${v.stockChannelName}` }
          })
          this.pager.total = total
        }
      } finally {
        this.tableLoading = false
        // platformSiteShopList存缓存
        localStorage.setItem(this.key, JSON.stringify(this.queryForm.platformSiteShopList))
        // dsb04/05新增保存表单
        this.$store.dispatch('saveForm/saveRedundantForm', this.queryForm)
        localStorage.setItem(
          this.key,
          JSON.stringify(this.queryForm.platformSiteShopList)
        )
      }
    },
    handleQueryFormReset() {
      const datas = this.dsbVertionOptions
      this.queryForm = Object.assign({}, this.$options.data().queryForm, {
        fcstVersionId: datas[datas.length - 1]?.version
      })
      this.handleQuery()
    },
    selectInit(row) {
      return row.detailList.some((v) => v.canProvisionFlag !== '是')
    }
  }
}
</script>

<style lang="scss" scoped>
// ::v-deep .el-table__header-wrapper .el-checkbox {
//   // display: none;//设置不成功，页面卡顿
//   visibility: hidden;
// }
</style>
